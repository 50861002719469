import React from 'react'
import { down, only, up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { calculateGridWidth } from '../../lib/utils/calculateGridWidth'
import Typography from '../common/Typography'

function ContactBox() {
	const { t } = useTranslation()

	return (
		<Block>
			<ItemBlock>
				<Typography.Body size="large" fontWeight="bold" className="contactName">
					{t('ContactBox.name2')}
				</Typography.Body>
				<Typography.Body size="large" className="contactValue">
					<a href={`tel:${t('ContactBox.value2')}`}>{t('ContactBox.value2')}</a>
				</Typography.Body>
			</ItemBlock>
			<ItemBlock>
				<Typography.Body size="large" fontWeight="bold" className="contactName">
					{t('ContactBox.name1')}
				</Typography.Body>
				<Typography.Body size="large" className="contactValue">
					<a href="mailto: tesser@tesser.io">tesser@tesser.io</a>
				</Typography.Body>
			</ItemBlock>
		</Block>
	)
}

const Block = styled.div`
	${up('lg')} {
		width: ${calculateGridWidth(6)};
	}

	${only('md')} {
		width: ${calculateGridWidth(3, true)};
	}

	${only('sm')} {
		width: ${calculateGridWidth(2.5, true)};
	}

	${down('xs')} {
		width: 100%;
	}
`

const ItemBlock = styled.div`
	word-break: keep-all;
	margin-bottom: 30px;

	.contactValue {
		margin-top: 16px;
	}

	.secondValue {
		margin-top: 4px;
	}

	& + & {
		margin-top: 40px;
	}

	/* ${up('lg')} {
		& + & {
			margin-top: 0;
		}
	} */
`

export default ContactBox
