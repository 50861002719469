import React from 'react'
import { down, only, up } from 'styled-breakpoints'
import { heading } from '../../lib/styles/typography'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Typography from '../common/Typography'
import Address from './Address'
import ContactBox from './ContactBox'
import spacing from '../../lib/styles/spacing'
import ContactForm from './ContactForm'

function ContactLayout() {
	const { t } = useTranslation()
	const breakpoints = useBreakpoint()

	return (
		<Block>
			<FormBox>
				<Title>
					<Typography.Display
						size="xxlarge"
						fontWeight="bold"
						className="mainTitle"
					>
						{t('ContactLayout.heading1')}
					</Typography.Display>
					<Typography.Heading size="small" className="subTitle">
						{breakpoints.sm ? (
							<>{t('ContactLayout.title2')}</>
						) : (
							<>{t('ContactLayout.title1')}</>
						)}
					</Typography.Heading>
				</Title>
				<ContactForm />
			</FormBox>
			<Columns>
				<ContactBox />
				<Address />
			</Columns>
		</Block>
	)
}

const Block = styled.div`
	max-width: 1920px;
	margin-top: 15rem;
	margin-bottom: 15rem;
	/* min-height: calc(100vh - 30rem); */
	display: flex;
	flex-direction: column;

	${up('sm')} {
		margin-top: 25rem;
	}

	${up('xl')} {
		padding-left: 104px;
		padding-right: 204px;
	}

	${down('sm')} {
		padding: 0;
		min-height: 0;
	}

	.mainTitle {
		${down('xs')} {
			font-size: 40px;
			line-height: 48px;
		}
	}

	.subTitle {
		margin-top: 32px;
	}
`

const FormBox = styled.div`
	${up('lg')} {
		display: flex;
		justify-content: space-between;
		gap: 60px;
		padding: 0;
	}
`

const Title = styled.div`
	${only('md')} {
		display: flex;
		gap: ${spacing.xxxl};

		.subTitle {
			${heading.xxsmall};
		}
	}

	${up('sm')} {
		margin-bottom: 4rem;
	}

	${down('xs')} {
		margin-bottom: 1rem;
	}
`

const Columns = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 183px;

	${up('sm')} {
		width: 80%;
		margin-left: auto;
	}

	${up('lg')} {
		width: 61%;
		margin-left: auto;
	}

	${down('xxs')} {
		flex-direction: column;
		justify-content: flex-end;
		gap: 2rem;
		margin-top: 120px;
	}
`

export default ContactLayout
