const spacing = {
	xxs: '4px',
	xs: '8px',
	s: '12px',
	m: '16px',
	l: '20px',
	xl: '24px',
	xxl: '28px',
	xxxl: '32px',
} as const

export default spacing

export type SpacingType = keyof typeof spacing
