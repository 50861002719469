import React from 'react'
import { down, only, up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { calculateGridWidth } from '../../lib/utils/calculateGridWidth'
import Typography from '../common/Typography'

function Address() {
	const { t } = useTranslation()

	return (
		<Block>
			<Typography.Body size="large" fontWeight="bold" className="address">
				{t('Address.heading')}
			</Typography.Body>
			<Typography.Body size="xlarge" className="addressItem">
				<span className="bold">{t('Address.title1')}</span>{' '}
				{t('Address.address1')}
			</Typography.Body>
			<Typography.Body size="xlarge" className="addressItem">
				<span className="bold">{t('Address.title2')}</span>
				{`\n`}
				{t('Address.address2')}
			</Typography.Body>
		</Block>
	)
}

const Block = styled.div`
	margin-left: 32px;

	${up('lg')} {
		width: ${calculateGridWidth(4.7, true)};
	}

	${only('md')} {
		width: ${calculateGridWidth(3, true)};
		margin-left: auto;
	}

	${only('sm')} {
		width: ${calculateGridWidth(2.5, true)};
	}

	${down('sm')} {
		margin-left: 0;
	}

	${down('xs')} {
		width: 100%;
	}

	.address {
		margin-bottom: 16px;
	}

	.addressItem {
		margin-bottom: 40px;
		word-break: keep-all;
	}

	.bold {
		font-weight: 700;
	}
`

export default Address
