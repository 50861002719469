import React from 'react'
import SEO from '../components/base/SEO'
import Layout from '../components/base/Layout'
import ContactLayout from '../components/contact/ContactLayout'
import ContactForm from '../components/contact/ContactForm'

const metaData = {
	title: '문의하기 | Tesser',
	description: '협업, 투자, 솔루션 구매 등. 편안하게 문의해 주시기 바랍니다.',
	keywords:
		'테서, tesser, 환자 데이터, 환자 서비스, 의료 인공지능 개발, 병원데이터, 임상데이터, 의학논문, 데이터분석, 데이터수집',
}

function Contact() {
	return (
		<Layout blackFooter>
			<SEO metaData={metaData} />
			<ContactLayout />
		</Layout>
	)
}

export default Contact
